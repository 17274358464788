<template lang="pug">
  div
    CCard
      CCardBody
        CRow
          CCol(sm=5)
            h4(class="card-title mb-0") Отчёт по реализации товаров

        vuecrud(selectedtable = "wb_salesreport", :columnsupdated="columnsupdated", :opts="opts", style="margin-top:15px")

</template>

<script>

export default {
  name: 'Partner_Incomes',
  data: function () {
    return {
      show: true,
      isCollapsed: true,

      opts: {
        canAdd: false,
        canEdit: false,
        canDelete: false,
        where: {
        }
      }

    }
  },
  methods: {
    columnsupdated: function (cols) {

      cols.delete('id');
      cols.delete('partner');
      cols.delete('number');
      cols.delete('odid');
      cols.delete('gNumber');
      cols.delete('rrd_id');
      cols.delete('rid');

      cols.set('realizationreport_id', {label: "Номер отчёта", hasFilter: false, });
      cols.set('suppliercontract_code', {label: "Договор", hasFilter: false, });
      cols.set('gi_id', {label: "Номер поставки", hasFilter: false, });
      cols.set('subject_name', {label: "Товар", hasFilter: false, });
      cols.set('nm_id', {label: "ID товара", hasFilter: false, });
      cols.set('brand_name', {label: "Бренд", hasFilter: false, });
      cols.set('sa_name', {label: "Ваш артикул", hasFilter: false, });
      cols.set('ts_name', {label: "Размер", hasFilter: false, });
      cols.set('barcode', {label: "Баркод", hasFilter: false, });
      cols.set('doc_type_name', {label: "Тип документа", hasFilter: false, });
      cols.set('quantity', {label: "Остатки", hasFilter: false, });
      cols.set('nds', {label: "Ставка НДС", hasFilter: false, });
      cols.set('cost_amount', {label: "Себестоимость, сумма", hasFilter: false, });
      cols.set('retail_price', {label: "Розничная цена", hasFilter: false, });
      cols.set('retail_amount', {label: "Сумма продаж/возвратов", hasFilter: false, });
      cols.set('retail_commission', {label: "Сумма комиссии продаж", hasFilter: false, });
      cols.set('sale_percent', {label: "Согласованная скидка", hasFilter: false, });
      cols.set('commission_percent', {label: "Процент комиссии", hasFilter: false, });
      cols.set('customer_reward', {label: "Вознаграждение покупателю", hasFilter: false, });
      cols.set('supplier_reward', {label: "Вознаграждение поставщику", hasFilter: false, });
      cols.set('office_name', {label: "Склад", hasFilter: false, });
      cols.set('supplier_oper_name', {label: "Обоснование для оплаты", hasFilter: false, });
      cols.set('order_dt', {label: "Дата заказа", hasFilter: false, });
      cols.set('sale_dt', {label: "Дата продажи", hasFilter: false, });
      cols.set('rr_dt', {label: "Дата операции", hasFilter: false, });
      cols.set('shk_id', {label: "ШК", hasFilter: false, });
      cols.set('retail_price_withdisc_rub', {label: "Цена розничная с учетом скидки", hasFilter: false, });
      cols.set('for_pay', {label: "К перечислению поставщику", hasFilter: false, });
      cols.set('for_pay_nds', {label: "К перечислению поставщику НДС", hasFilter: false, });
      cols.set('delivery_amount', {label: "Кол-во доставок", hasFilter: false, });
      cols.set('return_amount', {label: "Кол-во возвратов", hasFilter: false, });
      cols.set('delivery_rub', {label: "Стоимость логистики", hasFilter: false, });
      cols.set('gi_box_type_name', {label: "Тип коробов", hasFilter: false, });
      cols.set('product_discount_for_report', {label: "Согласованный продуктовый дисконт", hasFilter: false, });
      cols.set('supplier_promo', {label: "Промокод", hasFilter: false, });
      cols.set('supplier_spp', {label: "Скидка постоянного покупателя", hasFilter: false, });

      return cols;

    },
  }
}
</script>
